import React, { ReactNode } from 'react';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import { CssBaseline } from '@mui/material';
import {
  PreferencesProvider,
  PreferencesContext,
} from '@/context/preferences/PreferencesProvider';
import 'react-toastify/dist/ReactToastify.css';
import Notification from '@/components/Notification/Notification';
import FormProvider from '@/definitions/FormProvider';
import DefaultLayout from '@/layout/DefaultLayout';
import Theme from '@/definitions/theme/Theme';
import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';
import '@/styles/globals.css';
import { initialPreferences } from '@/context/preferences/initialPreferences';
import { EnvProvider } from '@/context/env/EnvProvider';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { appWithTranslation } from 'next-i18next';
import { PT_Sans_Narrow } from 'next/font/google';
import 'numeral/locales/it';
import 'numeral/locales/fr';

export const ptSansNarrow = PT_Sans_Narrow({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

import 'moment/locale/it';
import 'moment/locale/en-gb';
import GTM from '@/components/analytics/GTM';
import DefaultAuthLayout from '@/layout/DefaultAuthLayout';
import { mergeThemes } from '@/utils/mergeThemes';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();
  const session = pageProps?.session;
  const environment = pageProps?.environment;
  const disableGTM = pageProps?.disableGTM;

  function isAuthRoute(Component: ReactNode & { auth?: boolean }) {
    return (
      !router.pathname.startsWith('/login') &&
      !router.pathname.startsWith('/email-reset-password') &&
      !router.pathname.startsWith('/first-access') &&
      !router.pathname.startsWith('/reset-password')
    );
  }

  return (
    <main className={ptSansNarrow.className}>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={mergeThemes(environment, Theme)}>
          <CssBaseline />
          <RecoilRoot>
            <EnvProvider initialValue={environment}>
              <PreferencesProvider initialValue={initialPreferences}>
                <PreferencesContext.Consumer>
                  {() => (
                    <>
                      <Notification />
                      <SessionProvider
                        basePath="/next/api/auth"
                        session={session}
                      >
                        <GTM
                          disable={disableGTM}
                          environment={environment?.name}
                        />
                        <FormProvider>
                          {/*// @ts-ignore*/}
                          {isAuthRoute(Component) ? (
                            <DefaultLayout>
                              <Component {...pageProps} />
                            </DefaultLayout>
                          ) : (
                            <DefaultAuthLayout>
                              <Component {...pageProps} />
                            </DefaultAuthLayout>
                          )}
                        </FormProvider>
                      </SessionProvider>
                    </>
                  )}
                </PreferencesContext.Consumer>
              </PreferencesProvider>
            </EnvProvider>
          </RecoilRoot>
        </ThemeProvider>
      </CacheProvider>
    </main>
  );
}

export default appWithTranslation(App);
