import { useContext } from 'react';
import {
  PreferencesContext,
  PreferencesContextInterface,
} from '@/context/preferences/PreferencesProvider';

const usePreferences = (): PreferencesContextInterface => {
  return useContext(PreferencesContext);
};

export default usePreferences;
