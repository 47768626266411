import DateParser from '@/utils/DateParser';
const today = DateParser.dateToISOString(DateParser.now());
export const initialPreferences = {
  dataFormat: 'it',
  availableDataFormat: [
    { value: 'it', label: '1.000,32 - IT' },
    { value: 'fr', label: '1 000,32 - FR' },
    { value: 'en', label: '1,000.32 - EN' },
  ],
  dateFormat: 'it',
  availableDateFormat: [
    {
      value: 'it',
      label: `${DateParser.formatDate(today, {
        format: 'DDD t',
        locale: 'it',
      })} - IT`,
    },
    {
      value: 'en',
      label: `${DateParser.formatDate(today, {
        format: 'DDD t',
        locale: 'en',
      })} - EN`,
    },
  ],
  sidebarOpen: true,
};
