import { DateTime, Settings, Info } from 'luxon';

export const zoneList = {
  it: 'Europe/Rome',
  fr: 'Europe/Paris',
  en: 'utc',
};
/**
 * Utils class to manage DateTime operations
 */
export default class DateParser {
  /**
   *Format the given date using luxon. For the options please refer to:
   *
   * https://moment.github.io/luxon/#/
   *
   * @param date Data da formattare
   * @param options Opzioni per essere più precisi con la formattazione. Si può impostare il formato, il locale che si vuole usare e la timezone
   *
   * Per maggiori info sulle zone disponibili: https://moment.github.io/luxon/#/zones?id=specifying-a-zone
   *
   */

  static formatDate = (
    date: string | null | undefined,
    options: IFormatOptions = {
      format: 'dd LLL yyyy HH:mm:ss',
      locale: undefined,
      zone: { from: 'utc', to: 'utc' },
    }
  ): string => {
    if (date === null) {
      return null;
    }
    if (date === undefined || date === '') {
      date = new Date().toISOString();
    }
    const zone = options?.zone ?? { from: 'utc', to: 'utc' };
    const locale = options?.locale ?? undefined;
    const format = options?.format ?? 'dd LLL yyyy HH:mm:ss';
    const convertLocale = locale ?? DateParser.getLocale();

    const convertDate = DateTime.fromISO(date, { zone: zone.from });

    return convertDate
      .setZone(zone.to)
      .setLocale(convertLocale)
      .toFormat(format);
  };

  /**
   * Convert the given string date to ISOString
   * @param date
   */
  static dateToISOString = (
    date: string | Date | undefined = undefined
  ): string => {
    if (!date) return new Date().toISOString();
    if (date instanceof Date) return date.toISOString();
    return new Date(date).toISOString();
  };

  /**
   *
   * @param date string di partenza da cui ricavare e poi formattare la data
   * @param startFormat formato della data fornita
   * @param options opzioni per la conversione, La zona di partenza `from` verrà usata anche per la formattazione iniziale
   */
  static fromFormat = (
    date: string,
    startFormat: string,
    options: IFormatOptions = {
      format: 'dd LLL yyyy HH:mm:ss',
      locale: undefined,
      zone: { from: 'utc', to: 'utc' },
    }
  ) => {
    const zone = options?.zone ?? { from: 'utc', to: 'utc' };
    const convertDate = DateTime.fromFormat(date, startFormat, {
      zone: zone.from,
    });
    if (convertDate instanceof DateTime && convertDate.isValid) {
      return DateParser.formatDate(convertDate.toISO(), options);
    }
    return 'invalid_date';
  };

  /**
   * Convert the given string date to Date
   * @param date
   */
  static toDate = (date: string): Date => {
    return DateTime.fromISO(date).toJSDate();
  };

  static startOf = (
    date?: string,
    start: string = 'month',
    format: string = 'yyyy-mm-dd'
  ): string => {
    const jsDate = date ? new Date(date) : new Date();
    return DateTime.fromJSDate(jsDate).startOf(start).toFormat(format);
  };
  /**
   * Get current datetime
   */
  static now = () => {
    return DateTime.now();
  };

  /**
   * Set the locale
   * @param locale
   */
  static setLocale = (locale: string = 'en'): void => {
    Settings.defaultLocale = locale;
  };
  /***
   *Get current locale
   * */
  static getLocale = (): string => {
    const locale = navigator.languages?.[0];
    if (locale) {
      return locale;
    } else {
      DateParser.setLocale('en');
      return 'en';
    }
  };

  /***
   * Convert date to a given locale
   * @param date
   * @param locale
   */ static convertToLocale(date: string, locale: string) {
    return new Date(date).toLocaleString(locale);
  }

  /**
   * convert the given date to the locale set
   * @param date
   */
  static getDate = (date: string): Date => {
    return new Date(date);
  };

  /**
   * Controlla che la data sia valida
   * @param date string ISO che rappresenta la data
   */
  static isDateValid = (date: string) => {
    return DateTime.fromISO(date).isValid;
  };

  /**
   * Remove week from given date
   * @param dt
   * @param n
   */
  static removeWeekFromDate(dt, n = 1) {
    dt = new Date(dt);
    return new Date(dt.setDate(dt.getDate() - n * 7));
  }

  /**
   * Get week day as string by providing the number of the day
   * @param weekDay
   */

  static dayOfWeekAsString(weekDay) {
    return Info.weekdays()[weekDay];
  }
  /**
   * Add/Remove week on date
   * @param dt
   * @param n
   * @returns {Date}
   */
  static addWeekFromDate(dt, n = 1) {
    dt = new Date(dt);
    return new Date(dt.setDate(dt.getDate() + n * 7));
  }

  /**
   * Aggiungi alla data di partenza un dato valore
   * @param date Data di partenza
   * @param duration Ammontare da aggiungere. Per maggiori info: https://moment.github.io/luxon/api-docs/index.html#datetimeplus
   *
   */
  static add(date: string, duration: Object | number = 0) {
    return DateTime.fromISO(date, { zone: 'utc' }).plus(duration);
  }
  /**
   * Sottrai alla data di partenza un dato valore
   * @param date Data di partenza
   * @param duration Ammontare da sottrarre. Per maggiori info: https://moment.github.io/luxon/api-docs/index.html#datetimeminus
   *
   */
  static subtract(date: string, duration: Object | number = 0) {
    return DateTime.fromISO(date, { zone: 'utc' }).minus(duration);
  }
  /**
   * Ottieni la differenza tra due date
   * @param date_start Data di partenza
   * @param date_end Data di fine
   * @param unit unitàdi ritorno della differenza. Per maggiori info: https://moment.github.io/luxon/api-docs/index.html#datetimediff
   *
   */
  static diff(
    date_start: string,
    date_end: string,
    unit: string | string[] = 'milliseconds'
  ) {
    const ds = DateTime.fromISO(date_start);
    const de = DateTime.fromISO(date_end);
    const result = ds > de ? ds.diff(de, unit) : de.diff(ds, unit);
    return result.toObject();
  }

  static getTimezone = (): string => {
    return 'Europe/Rome';
  };
}
interface IFormatOptions {
  format?: string;
  locale?: string;
  zone?: { from?: string; to?: string };
}
