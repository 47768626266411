import {
  Context,
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

export interface EnvInterface {}

const defaultContextValue: EnvInterface = {
  routes: [],
};

export const EnvContext: Context<EnvInterface> =
  createContext(defaultContextValue);

export const EnvProvider = ({
  children,
  initialValue,
}: {
  children: ReactNode;
  initialValue: any;
}): ReactElement => {
  const [environment, setEnvironment] = useState<EnvInterface>(
    initialValue ?? defaultContextValue
  );

  useEffect(() => {
    initialValue && setEnvironment(initialValue);
  }, [initialValue]);

  return (
    <EnvContext.Provider value={environment}>{children}</EnvContext.Provider>
  );
};
