import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { NotificationImportant } from '@mui/icons-material';

const DefaultSidebarMenu = [
  {
    pathname: '/',
    name: 'homepage',
    title: 'Home',
    icon: () => <HomeOutlinedIcon />,
  },
  /*{
    pathname: '/routes-plannet',
    name: 'routes_plannet',
    title: 'Percorsi',
    icon: () => <AppsOutlinedIcon />,
  },*/
  {
    pathname: '/routes',
    name: 'routes',
    title: 'Percorsi',
    icon: () => <AppsOutlinedIcon />,
  },
  {
    pathname: '/reports',
    name: 'reports',
    title: 'Reports',
    icon: () => <InsertDriveFileOutlinedIcon />,
  },
  {
    pathname: '/plants',
    name: 'plants',
    title: 'Sedi',
    icon: () => <DomainOutlinedIcon />,
  },
  {
    pathname: '/optimizations',
    name: 'optimizations',
    title: 'Ottimizzazioni',
    icon: () => <DonutLargeOutlinedIcon />,
  },
  {
    pathname: '/efficiency-measures',
    name: 'efficiency_measures',
    title: `Misure d'efficienza`,
    icon: () => <EventNoteOutlinedIcon />,
  },
  {
    pathname: '/machines',
    name: 'machines',
    title: 'Macchinari',
    icon: () => <KitchenOutlinedIcon />,
  },
  {
    pathname: '/alarms',
    name: 'alarms',
    title: 'Allarmi',
    icon: () => <NotificationsNoneOutlinedIcon />,
  },
  {
    pathname: '/alarms/notification',
    name: 'alarms-notification',
    title: 'Notifica Allarmi',
    icon: () => <NotificationImportant />,
  },
  {
    pathname: '/configurations',
    name: 'configurations',
    title: 'Configurazioni',
    icon: () => <SettingsOutlinedIcon />,
    disabled: true,
  },
  {
    pathname: '/proposals',
    name: 'proposals',
    title: 'Proposte',
    icon: () => <DescriptionOutlinedIcon />,
  },
  {
    pathname: '/archive',
    name: 'archive',
    title: 'Archivio',
    icon: () => <FolderOutlinedIcon />,
  },
  {
    pathname: '/users',
    name: 'users',
    title: 'Utenti',
    icon: () => <PeopleAltOutlinedIcon />,
  },
  {
    pathname: '/companies',
    name: 'companies',
    title: 'Aziende',
    icon: () => <ApartmentOutlinedIcon />,
  },
];

export default DefaultSidebarMenu;
