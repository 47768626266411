import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, MenuItem, Tooltip } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { ActionsMenu, MenuProvider } from '@arkejs/mui';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    cursor: 'pointer',
  },
  avatarCircle: {
    width: 32,
    height: 32,
    padding: 4,
    border: '1px solid #fff',
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
}));

function UserMenu(props) {
  const { classes } = useStyles();
  const { data } = useSession();
  const { user } = data || {};
  const router = useRouter();
  const { sidebarOpen } = props;
  const { t } = useTranslation('common');

  const items = [
    <MenuItem
      key="detail"
      onClick={() => router.push({ pathname: '/user/detail' })}
    >
      <PermIdentityOutlinedIcon />
      <Typography>{t('pathname./user/detail', 'User')}</Typography>
    </MenuItem>,
    /*<MenuItem onClick={() => history.push({ pathname: '/user/settings' })}>
      <SettingsOutlinedIcon />
      <Typography>Impostazioni</Typography>
    </MenuItem>,*/
    <MenuItem
      key={t('pathname./logout')}
      onClick={() => router.push({ pathname: '/logout' })}
    >
      <ExitToAppOutlinedIcon />
      <Typography>{t('pathname./logout', 'Logout')}</Typography>
    </MenuItem>,
  ];
  return (
    <MenuProvider menu={<ActionsMenu items={items} />}>
      <div className={classes.root}>
        <div className={classes.avatarCircle}>
          <Tooltip
            placement={'right'}
            title={
              sidebarOpen
                ? ''
                : user?.first_name
                ? user?.first_name
                : user?.username
            }
          >
            <PermIdentityOutlinedIcon />
          </Tooltip>
        </div>
        {sidebarOpen && (
          <>
            <Typography variant="h5">
              {user && user.first_name ? user.first_name : 'Account'}
            </Typography>
            <ExpandMoreOutlinedIcon />
          </>
        )}
      </div>
    </MenuProvider>
  );
}

UserMenu.defaultProps = {};

export default UserMenu;
