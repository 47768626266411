import {
  Context,
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
} from 'react';
import { useLocalStorage } from 'react-use';
import { useRouter } from 'next/router';
import DateParser from '@/utils/DateParser';

interface PreferencesInterface {
  dataFormat: string;
  availableDataFormat: { value: string; label: string }[];
  dateFormat: string;
  availableDateFormat: { value: string; label: string }[];
  sidebarOpen: boolean;
}

export interface PreferencesContextInterface {
  preferences: PreferencesInterface;
  setPreferences?: (newPreferences: Partial<PreferencesInterface>) => void;
}

export const defaultContextValue: PreferencesContextInterface = {
  preferences: {
    dataFormat: 'it',
    availableDataFormat: [
      { value: 'it', label: '1.000,32 - IT' },
      { value: 'fr', label: '1 000,32 - FR' },
      { value: 'en', label: '1,000.32 - EN' },
    ],
    dateFormat: 'it',
    availableDateFormat: [
      {
        value: 'it',
        label: `${DateParser.formatDate(undefined, {
          format: 'DDD t',
          locale: 'it',
        })} - IT`,
      },
      {
        value: 'en',
        label: `${DateParser.formatDate(undefined, {
          format: 'DDD t',
          locale: 'en',
        })} - EN`,
      },
    ],
    sidebarOpen: true,
  },
};

export const PreferencesContext: Context<PreferencesContextInterface> =
  createContext(defaultContextValue);

export const PreferencesProvider = ({
  children,
  initialValue,
}: {
  children: ReactNode;
  initialValue: any;
}): ReactElement => {
  const router = useRouter();
  const [preferences, setPreferences] = useLocalStorage<PreferencesInterface>(
    'preferences',
    initialValue ?? defaultContextValue.preferences
  );

  useEffect(() => {
    DateParser.setLocale(preferences.dateFormat);
  }, [preferences.dateFormat]);

  useEffect(() => {
    setPreferences((preferences) => ({
      ...preferences,
      dataFormat: router.locale,
    }));
  }, [router.locale]);

  const onSetPreferences = (newPreferences: PreferencesInterface) => {
    const currentPreferences = { ...preferences };
    setPreferences((prevState) => ({ ...prevState, ...newPreferences }));

    if (newPreferences.dataFormat !== currentPreferences.dataFormat) {
      router.push(router.pathname, router.pathname, {
        locale: newPreferences.dataFormat,
      });
    }
  };

  return (
    <PreferencesContext.Provider
      value={{
        preferences,
        setPreferences: onSetPreferences,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
