import { useContext } from 'react';
import { EnvContext } from '@/context/env/EnvProvider';

const useEnv = (): any => {
  return useContext(EnvContext);
};

export default useEnv;

/*export default function useEnv() {
  const environment = {
    key: 'enersem',
    router: {
      routes: [],
      excludes: ['/login'],
      redirects: [{ from: '/login', to: '/' }],
    },
    theme: {},
    settings: {
      logo: '',
      small_logo: '',
      logoSmall: '/logo/logo-enersem.png',
      logoLarge: '/logo/logo-extened-enersem.png',
      sidebar: {
        extendedName: 'Arkemis',
        shortName: 'Ark',
        routes: DefaultSidebarMenu,
      },
    },
  };
  return { environment };
}*/
