import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Tooltip, useTheme } from '@mui/material';
import DefaultSidebar from '@/hooks/DefaultSidebar';
import { useRouter } from 'next/router';
import { usePreferences } from '@/hooks';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    padding: '6px 0',
    paddingLeft: 16,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 22,
    },
  },
}));

export function SidebarRouteItem({
  pathname,
  ...props
}: {
  pathname?: string;
  title?: string;
  icon?: ReactNode;
}) {
  const { t } = useTranslation(['common']);
  const { preferences } = usePreferences();
  const { classes } = useStyles();
  const theme = useTheme();
  const defaultRoute = DefaultSidebar.filter(
    (el) => el.pathname === pathname
  )?.[0];

  const router = useRouter();

  const isActiveRoute = router.pathname == pathname;
  const title = defaultRoute
    ? t(`pathname.${defaultRoute.pathname}`)
    : props.title;
  const icon = defaultRoute?.icon?.() ?? props.icon;
  const shouldDisplay = icon && title;

  return shouldDisplay ? (
    <div
      className={classes.root}
      style={
        // @ts-ignore
        isActiveRoute ? { background: theme.palette.background.light } : null
      }
    >
      <Tooltip
        placement={'right'}
        title={preferences.sidebarOpen ? '' : defaultRoute?.title ?? title}
      >
        <div className={classes.icon}>{icon}</div>
      </Tooltip>
      {preferences.sidebarOpen && (
        <Typography variant="h5" style={{ marginLeft: 16 }}>
          {title}
        </Typography>
      )}
    </div>
  ) : null;
}

export default SidebarRouteItem;
