import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import useEnv from '@/context/env/useEnv';
import { useSession } from 'next-auth/react';
import Sidebar from '../layout/sidebar/Sidebar';

const useStyles = makeStyles()((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    /* Use below to assign the env color to the sidebar */
    '*::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: 10,
    },
    '*::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 10,
    },
  },
  root: {
    // @ts-ignore
    background: theme.palette.background.light,
    display: 'flex',
  },
  content: {
    flex: 1,
    padding: 20,
  },
}));

function DefaultLayout(props) {
  const [shouldRender, setShouldRender] = useState(false);
  const { classes } = useStyles();
  const { children } = props;
  const environment = useEnv();
  const { routes, extendedName, shortName } = environment;

  const { data } = useSession();
  const { user } = data || {};

  const filteredRoutes =
    user?.type == 'C'
      ? routes.filter((route) => !route.pathname.includes('users'))
      : routes;

  // TODO: remove after MUI removal
  useEffect(() => {
    setShouldRender(true);
  }, []);

  return shouldRender ? (
    <div className={classes.root}>
      {filteredRoutes && (
        <Sidebar
          routes={filteredRoutes}
          extendedName={extendedName}
          shortName={shortName}
        />
      )}
      <div className={classes.content}>{children}</div>
    </div>
  ) : null;
}

export default DefaultLayout;
